import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRosterStatusTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('operation/roster-status-types', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRosterStatusType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`operation/roster-status-types/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRosterStatusType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`operation/roster-status-types/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
